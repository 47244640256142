/* You can add global styles to this file, and also import other style files */
.dx-button-mode-contained.dx-button-success {
    background-color: #337ab7;
 }

 .dx-button-mode-contained.dx-button-success.dx-state-hover {
    background-color: #275e8f;
}

.dx-button-mode-contained.dx-button-success.dx-state-focused {
    background-color: #1e496e;
}

body {
    font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;
    font-size: 14px;
}

.dx-widget {
    font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;
    font-size: 14px;
}

.dx-widget input {
    font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;
    font-size: 14px;
}

.dx-widget * {
    font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;
    font-size: 14px;
}

div[class*='-icon'] {
    font-family: 'DXIcons';
}

i[class*='-icon'] {
    font-family: 'DXIcons';
}

i[class*='fa'] {
    font-family: FontAwesome;
}

a[class*='-icon'] {
    font-family: 'DXIcons';
}

span[class*='-icon'] {
    font-family: 'DXIcons';
}

.dx-datagrid-group-closed {
    font-family: 'DXIcons';
}

.dx-datagrid-group-opened {
    font-family: 'DXIcons';
}

.dx-form-group-caption {
    font-size: 19px !important;
}

tspan {
    font-family: 'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana, sans-serif !important;
    font-size: 12px !important;
 }

 .nav-tabs .nav-link {
     font-size: 16px;
     font-weight: 400;
 }

.dx-toolbar-label .dx-toolbar-item-content > div {
    font-size: 20px;
}

div[class^='dx-switch'] {
    font-weight: 500;
}

.text-resume * {
    font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.grey-link {
    color: grey;
}

.dx-toast-custom {
    border-radius: 100px;
    background-color: #337ab7;
    margin-left: 13% !important;
}

.dx-toast-custom i {
    font-size: 26px;
    color: #337ab7;
    padding: 10px;
    border-radius: 50px;
    background-color: white;
}
